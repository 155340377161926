<template>
    <div>
        <BaseInfo :baseInfo="$store.state.user.baseInfo" />
        <NavBar
                title="健康宣教"
                left-arrow
                @click-left="$router.go(-1)"
        />
        <vcell style="margin-top: 10px" v-for="(type,index) in types" :key="index" @click="toExerciseType(type.typeCode,type.typeTitle)">
            <div style="float: left;width: 30%">
                <vimg
                        width="100"
                        height="70"
                        :src="require('../../img/'+type.typeRemark)"
                />
            </div>
            <div style="float: left;width: 70%;margin-top: 5px">
                <div class="text" style="text-align: center">
                    <span style="font-size: larger">{{type.typeTitle}}</span>
                </div>
                <div class="text" style="text-align: center;margin-top: 5px">
                    <span style="font-size: small">{{type.typeDes}}</span>
                </div>
            </div>
        </vcell>
        <br>
        <br>
        <RLFooter/>
    </div>
</template>

<script>

    import {Cell as vcell,Image as vimg} from 'vant';
    import {getFollows} from '@/api/follow';
    import {getExerciseTypeList} from '@/api/jkgl';
    import {setToken, removeToken, getToken} from '@/utils/auth';
    import {getUser} from '@/api/user';

    export default {
        name: 'jkgl',
        components: {
            vcell,vimg
        },
        data(){
            return {
                user:{
                    name:'',
                    idCard:'',
                    telephone:'',
                    gender:'1',
                    birthday:'',
                    address:'',
                    detail_address:'',
                    id:''
                },
                kptws:[],
                tysps:[],
                bwspHash:{},
                total_kp:0,
                size_kp:0,
                total_ty:0,
                size_ty:0,
                total_bw:0,
                size_bw:0,
                nodata:false,
                picUrl:'https://guanjietong.rilintech.com/',
                activeName:'-1',
                activeNameBw:'1',
                types:[]
            };
        },
        computed:{

        },
        methods:{
            toExerciseType(type_code,type_title){
                console.log(type_code)
                this.$router.push({path: '/jkgl_type/',query:{type_code:type_code,type_title:type_title}});

            },
            initidx(){
                console.info(this.$store.state.user);
                //this.$store.commit('updatePid',this.$route.params.pid);
                let query = this.$route.query;

                let follow_time=query.follow_time?query.follow_time : this.parseTime(new Date(),'{y}-{m}-{d}');
                getFollows({follow_time:follow_time,pid:this.$route.params.pid}).then((response) => {
                    if(response.code==200 ){
                        // this.loading=true;
                        this.active=response.data.total;

                        this.followcrfs=response.data.rows;
                        console.log(response.data)

                        if(this.followcrfs.length==0){
                            this.nodata = true
                        }
                    }
                });
                // getqueidxs({pid:this.$route.params.pid}).then((response) => {
                //     this.loading=true;
                //     if(this.followcrfs.length==0){
                //         this.followcrfs=response.data;
                //     }
                // });
            },
            showExe(send_id){
                this.$router.push({path: '/exe/'+send_id});
            },
            formaterState(fins){
                if(fins==1){
                    return '未完成';
                }else{
                    return '已完成';
                }
            },
            formaterTime(ctime,uptime){
                let res="";
                if(ctime){
                    res+="创建时间："+this.parseTime(ctime);
                }
                if(uptime){
                    res+="    更新时间："+this.parseTime(uptime);
                }
                return res;
            },
            initData(){
                // let query = this.$route.query;
                // this.follow_time=query.follow_time;
                // if(this.follow_time == undefined){
                //     this.follow_time = this.parseTime(new Date(),'{y}-{m}-{d}');
                // }
                getExerciseTypeList({patientid:this.user.id}).then((response) => {
                    console.log(response)
                    if(response.code==200 ){
                        this.types = response.data.types;
                        // this.tysps = response.data.tybw_sends;
                        // this.bwspHash = response.data.bwHash;

                        // this.total_kp = response.data.kptw_sends.length
                        // this.size_kp = Math.ceil(this.total_kp/2)
                        //
                        // this.total_ty = response.data.tybw_sends.length
                        // this.size_ty = Math.ceil(this.total_ty/2)
                    }

                });
            },
        }
        ,
        created() {
            //生产环境
            // var query = this.$route.query;
            // if(query.code || this.getQueryString('code')){
            //     // console.log("code")
            //     // console.log(query.code)
            //     // console.log(this.getQueryString('code'))
            //     //this.$store.commit('updateOpenid','123456');
            //     this.$store.commit('updateCode',query.code?query.code : this.getQueryString('code'));
            //     setToken(query.code?query.code : this.getQueryString('code'));
            //     getUser().then((res) => {
            //         console.info(res.data);
            //
            //         if(res.data){
            //             this.$store.commit('updateOpenid',res.data.openid);
            //             this.$store.commit('updatePid',res.data.id);
            //             this.$store.commit('updateBaseInfo',res.data);
            //             if(res.data.name !=''){
            //                 this.substatus = true
            //             }
            //             this.user.id=res.data.id;
            //             this.user.name=res.data.name;
            //             this.user.birthday=res.data.birthday;
            //             // this.user.idCard=response.data.idCard;
            //             this.user.telephone=res.data.telephone;
            //             this.user.gender=res.data.gender+'';
            //             this.user.address=res.data.address;
            //             this.user.detail_address=res.data.detail_address;
            //             // this.initBaseInfo();
            //
            //             this.initData()
            //
            //         }
            //     });
            //     //getBaseInfo({}).then((response) => {
            //     //   console.info(response);
            //     // });
            // }else{
            //     removeToken();
            // }
            // 开发环境
            // getUser().then((res) => {
            //     console.info(res.data);
            //
            //     if(res.data){
            //         this.$store.commit('updateOpenid',res.data.openid);
            //         this.$store.commit('updatePid',res.data.id);
            //         this.$store.commit('updateBaseInfo',res.data);
            //         if(res.data.name !=''){
            //             this.substatus = true
            //         }
            //         this.user.id=res.data.id;
            //         this.user.name=res.data.name;
            //         this.user.birthday=res.data.birthday;
            //         // this.user.idCard=response.data.idCard;
            //         this.user.telephone=res.data.telephone;
            //         this.user.gender=res.data.gender+'';
            //         this.user.address=res.data.address;
            //         this.user.detail_address=res.data.detail_address;
            //         // this.initBaseInfo();
            //
            //         this.initData()
            //
            //     }
            // });
          // 开发环境
          if(process.env.NODE_ENV=='development'){
            // 开发环境
            getUser().then((res) => {
              console.info(res.data);

              if(res.data){
                this.$store.commit('updateOpenid',res.data.openid);
                this.$store.commit('updatePid',res.data.id);
                this.$store.commit('updateBaseInfo',res.data);
                if(res.data.name !=''){
                  this.substatus = true
                }
                this.user.id=res.data.id;
                this.user.name=res.data.name;
                this.user.birthday=res.data.birthday;
                // this.user.idCard=response.data.idCard;
                this.user.telephone=res.data.telephone;
                this.user.gender=res.data.gender+'';
                this.user.address=res.data.address;
                this.user.detail_address=res.data.detail_address;
                // this.initBaseInfo();
                this.user_empty = false

                this.initData();

              }
            });
          }else{
            //生产环境
            var query = this.$route.query;
            let codeTemp = getToken();

            if(query.code || this.getQueryString('code') || codeTemp){
              // console.log("code")
              // console.log(query.code)
              // console.log(this.getQueryString('code'))
              //this.$store.commit('updateOpenid','123456');
              if(query.code || this.getQueryString('code')){
                this.$store.commit('updateCode',query.code?query.code : this.getQueryString('code'));
                setToken(query.code?query.code : this.getQueryString('code'));
              }
              getUser().then((res) => {
                console.info(res.data);

                if(res.data){
                  this.$store.commit('updateOpenid',res.data.openid);
                  this.$store.commit('updatePid',res.data.id);
                  this.$store.commit('updateBaseInfo',res.data);
                  if(res.data.name !=''){
                    this.substatus = true
                  }
                  this.user.id=res.data.id;
                  this.user.name=res.data.name;
                  this.user.birthday=res.data.birthday;
                  // this.user.idCard=response.data.idCard;
                  this.user.telephone=res.data.telephone;
                  this.user.gender=res.data.gender+'';
                  this.user.address=res.data.address;
                  this.user.detail_address=res.data.detail_address;
                  // this.initBaseInfo();
                  this.user_empty = false

                  this.initData();

                }
              });
              //getBaseInfo({}).then((response) => {
              //   console.info(response);
              // });
            }else{
              removeToken();
            }
          }
        },
    }
</script>

<style scoped>

    .is_completed{
        color: aqua;
    }
    .is_uncompleted{
        color:red;
    }

</style>
