import request from '@/utils/request'
//  获取随访问卷列表
export function getFollows(params) {
    params.dataType='noKey';
    return request({
        url: '/followup/sendInfo',
        method: 'get',
        params:params
      })
  }
// 获取crf结构内容
export function getCrf(params) {
    params.dataType='noKey';
    return request({
        url: '/followup/getcrf',
        method: 'post',
        params:params
      })
  }
// 获取注册问卷
export function getFollowCrf(params) {
    params.dataType='noKey';
    return request({
        url: '/crf/newCrf',
        method: 'get',
        params:params
      })
  }

  //保存注册问卷crf
export function saveFollowCrf(params,data){
    params.dataType='noKey';
    return request({
        url: '/crf/saveCrfData',
        method: 'post',
        params:params,
        data:data
    })
}
  //保存crf
export function saveData(params,data){
    params.dataType='noKey';
    return request({
        url: '/followup/savecrf',
        method: 'post',
        params:params,
        data:data
    })
}
// 获取体检指标关注项
export function getTjzbItem(params) {
    console.log(params)
    params.dataType='noKey';
    console.log(params)

    return request({
        url: '/examination/tjzbitem_info',
        method: 'get',
        params:params
    })
}

//   // 查询问卷结构
// export function getquestus(params) {
//     params.dataType='noKey';
//     return request({
//         url: '/ques/que',
//         method: 'post',
//         params:params
//       })
//   }
//
//
//   // 查询问卷数据
// export function getqueDatas(params) {
//     params.dataType='noKey';
//     return request({
//         url: '/ques/datas',
//         method: 'post',
//         params:params
//       })
//   }
//
//   export function saveDatas(params,data){
//     params.dataType='noKey';
//     return request({
//         url: '/ques/saveDatas',
//         method: 'post',
//         params:params,
//         data:data
//       })
//   }
//
//
//
//   export function pushques(params) {
//     params.dataType='noKey';
//     return request({
//         url: '/ques/push',
//         method: 'post',
//         params:params
//       })
//   }