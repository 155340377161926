// import {getToken} from '@/utils/auth';

import request from '@/utils/request';




export function getExerciseList(params){
   // var token=getToken();
   // if(token){
   //      params.code=token;
   // }
    params.dataType='noKey';
    return request({
        url: '/patient_sends/send_info_exe.json',
        method: 'get',
        params:params
      })

}

export function getExerciseTypeList(params){
   // var token=getToken();
   // if(token){
   //      params.code=token;
   // }
    params.dataType='noKey';
    return request({
        url: '/followup/sendInfoExeType',
        method: 'get',
        params:params
      })

}

export function getExerciseType(params){
   // var token=getToken();
   // if(token){
   //      params.code=token;
   // }
    params.dataType='noKey';
    return request({
        url: '/followup/sendInfoExeList',
        method: 'get',
        params:params
      })

}

export function getExe(id){
   // var token=getToken();
   // if(token){
   //      params.code=token;
   // }
    return request({
        url: '/followup/getExe',
        method: 'get',
        params: {
            id: id,
            dataType:'noKey'
        }
      })

}
